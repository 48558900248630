.page-wrappers{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}


.blog-page-wrapper{
    padding-top:100px;

    .blog-page-container{
        display: flex;
        flex-direction: column;
        max-width: 1200px;
        padding: 20px;

        h3 {
            text-align: center;
            width: 100%;
        }
    }
}