.site-name,
.menu-section a {
  font-weight: bold;
  color: #333333;
}

.header-wrapper {
  display: flex;
  width: 100vw;
  justify-content: center;
  background: #fadadd;
  position: absolute;
  top: 0;
  left: 0;
}

.header-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px;
}

.header-container div {
  display: flex;
}

.menu-section {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  column-gap: 20px;
}
