.page-wrappers {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.blogs-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  padding-top: 80px;
  row-gap: 20px;
  align-items: center;

  a.blog-link {
    text-decoration: none;
    color: #333333;
    width: 100%;
  }

  .blog-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    // border: 2px solid #faa0a0;
    border-radius: 12px;
    // background-color: #fadadd;
    background: #ffffff;

    h3,
    h6,
    h5 {
      padding: 5px;
      margin: 0;
    }

    p {
      padding: 10px 5px;
      font-size: 16px;
    }
  }

  .blog-card:hover {
    cursor: pointer;
  }
}

.blog-page-wrapper {
  padding-top: 50px;

  .blog-page-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 20px;
    text-align: center;
  }
}
